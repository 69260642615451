<template>
  <b-row>
    <b-col sm="12" class="mb-1 text-right">
      <b-button variant="primary" @click="addNumbering"><b-icon icon="plus"></b-icon> Tambah</b-button>
    </b-col>
    <b-col sm="12">
      <b-table
        ref="datatable"
        striped
        hover
        responsive
        :items="rows"
        :fields="fields"
        show-empty
      >
        <template #cell(id)="data">
          <b-button class="mr-1" @click="editNumbering(data.item)" variant="outline-primary" size="sm"><b-icon icon="pencil"></b-icon></b-button>
          <b-button @click="deleteNumbering(data.item.id)" variant="outline-primary" size="sm"><b-icon icon="trash"></b-icon></b-button>
        </template>
        <template #empty>
          <h5>Tidak ada data.</h5>
        </template>
      </b-table>
      <b-modal title="Format Penomoran" ref="formModal" hide-footer no-close-on-backdrop no-close-on-esc>
        <form-validation @submit="submitForm">
          <b-row>
            <b-col sm="12">
              <form-input :rules="{required: true, numeric: true}" v-model="sort_no" label="Urut"></form-input>
            </b-col>
            <b-col sm="12">
              <form-input v-model="prefix" label="Prefix"></form-input>
            </b-col>
            <b-col sm="12">
              <form-select-static @value-changed="format=''" :clearable="false" name="type" :rules="{required: true}" :options="typeOptions" v-model="type" label="Tipe"></form-select-static>
            </b-col>
            <b-col sm="12" :hidden="type!='Counter'">
              <form-input :rules="{numeric: true, required: type=='Counter',min_value:1}" v-model="digit" label="Digit"></form-input>
            </b-col>
            <b-col sm="12" :hidden="type!='Counter'">
              <form-input :rules="{numeric: true, required: type=='Counter',min_value:1}" v-model="last_value" label="Nilai Terakhir"></form-input>
            </b-col>
            <b-col sm="12" :hidden="type&&type=='Counter'">
              <form-select-static @value-changed="formatChange" :clearable="false" name="type" :rules="{required: type.includes('Date','Roman')}" :options="formatOptions" v-model="format" label="Format"></form-select-static>
            </b-col>

            <b-col sm="12" class="mt-3">
              <button-save :isloading="isloading"></button-save>
              <b-button class="ml-1" @click="$refs.formModal.hide()" variant="outline-primary">Batal</b-button>
            </b-col>
          </b-row>
        </form-validation>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {BTable,BIcon,BButton} from 'bootstrap-vue'
import FormValidation from '@/my-components/FormValidation.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import FormSelectStatic from '@/my-components/FormSelectStatic.vue'
import { mapActions } from 'vuex'

export default {
  data(){
    return {
      rows:[],
      fields:[
        {key:'sort_no',label:'Urut'},
        {key:'prefix',label:'Prefix'},
        {key:'type',label:'Tipe'},
        {key:'name_format',label:'Format'},
        {key:'last_value',label:'Nilai Terakhir'},
        {key:'id',label:''},
      ],
      typeOptions:[
        {"label":  "Roman", "value": "Roman"},
        {"label": "Date", "value": "Date"},
        {"label": "Counter", "value": "Counter"}
      ],
      format1:[
        { "label": "Tahun 4-Digit (yyyy)", "value": "R-Y"},
        { "label": "Tahun 2-Digit (yy)", "value": "R-y"},
        { "label": "Bulan (01-12)", "value": "R-m"},
        { "label": "Day (01-31)", "value": "R-d"},
      ],
      format2:[
        { "label": "Tahun 4-Digit (yyyy)", "value": "D-Y"},
        { "label": "Tahun 2-Digit (yy)", "value": "D-y"},
        { "label": "Bulan (01-12)", "value": "D-m"},
        { "label": "Bulan Text (January - Desember)", "value": "D-M"},
        { "label": "Day (01-31)", "value": "D-d"},
      ],
      isloading: false,
      id:'',
      sort_no: '',
      prefix: '',
      type: '',
      format: '',
      name_format: '',
      last_value: 1,
      id_branch: this.$route.params.id,
      id_type_transaction: this.$route.params.number,
      digit: 1
    }
  },
  components:{
    BTable,
    BIcon,
    BButton,
    FormInput,
    FormValidation,
    ButtonSave,
    FormSelectStatic
  },
  computed:{
    formatOptions(){
      if(this.type == "Roman") return this.format1
      else return this.format2
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'companies/getNumberDetail',
      dispatchStore: 'companies/storeNumbering',
      dispatchUpdate: 'companies/updateNumbering',
      dispatchDelete: 'companies/destroyNumbering',
    }),
    async getData(){
      try {
        const id_branch = this.$route.params.id
        const id_type_transaction = this.$route.params.number
        const data = await this.dispatchShow({id_branch,id_type_transaction})
        this.rows = data
      } catch (error) {
        this.handleError(error)        
      }
    },
    formatChange(json){
      if(json.label) this.name_format = json.label;
      else this.name_format = '';
    },
    addNumbering(){
      this.id = null
      this.sort_no = ''
      this.prefix = ''
      this.type = ''
      this.format = ''
      this.name_format = ''
      this.last_value = 1
      this.id_branch = this.$route.params.id
      this.id_type_transaction = this.$route.params.number
      this.digit = 1

      this.$refs.formModal.show()
    },
    editNumbering(data){
      this.id = data.id
      this.sort_no = data.sort_no
      this.prefix = data.prefix
      this.type = data.type
      this.format = data.format
      this.name_format = data.name_format
      this.last_value = data.last_value
      this.id_branch = data.id_branch
      this.id_type_transaction = data.id_type_transaction
      this.digit = data.digit

      this.$refs.formModal.show()
    },
    async submitForm(){
      const {id,sort_no,prefix,type,format,name_format,last_value,id_branch,id_type_transaction,digit} = this
      let params = {id,sort_no,prefix,type,format,name_format,last_value,id_branch,id_type_transaction,digit}
      if(type=='Counter'){
        params.format = '0'.repeat(digit)
        params.name_format = `Counter "${'0'.repeat(digit)}"`
      }
      this.isloading=true
      try {
        if (!this.id) await this.dispatchStore(params);
        else await this.dispatchUpdate(params);
        this.$refs.formModal.hide()
        this.getData()
        this.toastSuccess("OK","Data berhasil disimpan!")
      } catch (error) {
        this.handleError(error)
      }
      this.isloading=false
    },
    deleteNumbering(value){
      this.$swal({
        title:`Konfirmasi Hapus Data!`,
        text:`Anda akan menghapus data ini. Setelah dihapus data tidak dapat dipulihkan kembali`,
        icon:`warning`,
        showCancelButton:true,
        cancelButtonText:`Tidak`,
        confirmButtonText:`Ya`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if(result.value) {
          try {
            await this.dispatchDelete(value)
            this.getData()
            this.toastSuccess("OK","Data berhasil dihapus!")
          } catch (error) {
            this.handleError(error)
          }
        }
      })
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>